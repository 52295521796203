import React, { useEffect, useState } from "react";
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { useParams } from "react-router-dom";
import api from "../logic/apiHandler";
import {
  Button,
  Grid,
  GridColumn,
  Icon,
  List,
  ListItem,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
} from "semantic-ui-react";
import { convertIDToHex } from "../logic/hexStringConverter";
import { Link } from "react-router-dom";

export default function CommitteeUsersList() {
  const [committeeUsers, setCommitteeUsers] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const { committeeID } = useParams();

  useEffect(() => {
    (async () => {
      try {
        setCommitteeUsers(await api.committeeData.users(committeeID));
      } catch (e) {
        console.log("e", e);
      }
    })();
  }, [committeeID]);

  const columns = [
    {
      header: "ID",
      accessorKey: "userID",
      cell: ({ row }) => (
        <Link
          to={`https://connect.libdems.org.uk/UserDetails.aspx?UserID=${convertIDToHex(row.original.userID)}`}
          target="_blank"
        >
          {row.original.userID}
        </Link>
      ),
    },
    {
      header: "Name",
      accessorKey: "userName",
    },
    {
      header: "Address",
      accessorKey: "Address1",
    },
    {
      header: "City",
      accessorKey: "City",
    },
    {
      header: "Postal Code",
      accessorKey: "PostalCode",
    },
    {
      header: "Email",
      accessorKey: "email",
    },
    {
      header: "Date Created",
      accessorKey: "dateCreated",
      cell: ({ row }) => row.original.dateCreated.split("T")[0],
    },
    {
      header: "Last Logged In",
      accessorKey: "dateOfLastLogin",
      cell: ({ row }) => row.original.dateOfLastLogin?.split("T")[0] ?? "N/A", // date or datetime?
    },
    {
      header: "Other Committees",
      accessorKey: "otherCommittees",
      cell: ({ row }) => truncateTextAndRenderModal(row.original.otherCommittees, "Other Committees"),
      enableSorting: false,
    },
    {
      header: "MC Profile",
      accessorKey: "mcProfile",
    },
    {
      header: "VF Profile",
      accessorKey: "vfProfile",
    },
    {
      header: "Districts",
      accessorKey: "districts",
      cell: ({ row }) => truncateTextAndRenderModal(row.original.districts, "Districts"),
      enableSorting: false,
    },
    {
      header: "MC VANID",
      accessorKey: "mcVANID",
    },
    {
      header: "VF VANID",
      accessorKey: "vfVANID",
    },
  ];

  function truncateTextAndRenderModal(text, title) {
    if (text.length < 100) {
      return text;
    }
    let list = text.split(",").map((item) => {
      return <ListItem>{item}</ListItem>;
    });

    const half = Math.ceil(list.length / 2);
    return (
      <div>
        {text.substr(0, 100)}
        <Modal
          onClose={() => setOpenModal(false)}
          onOpen={() => setOpenModal(true)}
          open={openModal}
          trigger={<Icon name="ellipsis horizontal" color="yellow" />}
        >
          <ModalHeader>{title}</ModalHeader>
          <ModalContent>
            <Grid>
              <GridColumn width={8}>{<List bulleted>{list.slice(0, half)}</List>}</GridColumn>
              <GridColumn width={8}>{<List bulleted>{list.slice(half)}</List>}</GridColumn>
            </Grid>
          </ModalContent>
          <ModalActions>
            <Button onClick={() => setOpenModal(false)} style={{ backgroundColor: "#faa61a" }}>
              Got it!
            </Button>
          </ModalActions>
        </Modal>
      </div>
    );
  }

  const table = useReactTable({
    columns,
    data: committeeUsers,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div>
      <h3>Committee Users</h3>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  className={header.column.getCanSort() ? "cursor-pointer select-none" : ""}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  {
                    <div
                      title={
                        header.column.getCanSort()
                          ? header.column.getNextSortingOrder() === "asc"
                            ? "Sort ascending"
                            : header.column.getNextSortingOrder() === "desc"
                            ? "Sort descending"
                            : "Clear sort"
                          : undefined
                      }
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: <Icon name="caret up" color="yellow" />,
                        desc: <Icon name="caret down" color="yellow" />,
                      }[header.column.getIsSorted()] ?? null}
                    </div>
                  }
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
