import React, { useEffect, useState } from "react";
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import api from "../logic/apiHandler";
import { Helmet } from "react-helmet-async";
import { Icon } from "semantic-ui-react";
import { useParams } from "react-router-dom";
import Dimmer from "./Dimmer";

export default function PDWalksTable({ areaName }) {
  const [walksData, setWalksData] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [error, setError] = useState(null);
  const { regArea, pd } = useParams();

  useEffect(() => {
    async function getWalksData() {
      setDataLoaded(false);
      setError(null);

      try {
        setWalksData(await api.seatData.walkData(regArea, pd));
        setDataLoaded(true);
      } catch (e) {
        setError(e);
        setDataLoaded(true);
      }
    }

    getWalksData();
  }, []);

  const columns = [
    {
      header: "Local Authority",
      accessorKey: "District_Borough_Unitary_Region_Name",
    },
    {
      header: "PD",
      accessorKey: "POLLING_DISTRICT_NUMBER",
    },
    {
      header: "Route Number",
      accessorKey: "RouteNumber",
    },
    {
      header: "Walk Turf Name",
      accessorKey: "WalkTurfName",
    },
    {
      header: "Person Count",
      accessorKey: "personCount",
    },
    {
      header: "Door Count",
      accessorKey: "doorCount",
    },
    {
      header: "Letter Count",
      accessorKey: "letterCount",
    },
    {
      header: "Has Deliverer",
      accessorKey: "hasDeliverer",
    },
    {
      header: "Has Wholesaler",
      accessorKey: "hasWholesaler",
    },
  ];

  const table = useReactTable({
    columns,
    data: walksData,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div>
      <Helmet>
        <title>{areaName ?? ""} - Walks</title>
      </Helmet>
      <Dimmer error={error} dataLoaded={dataLoaded} />
      {dataLoaded && (
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    <div
                      className={header.column.getCanSort() ? "cursor-pointer select-none" : ""}
                      onClick={header.column.getToggleSortingHandler()}
                      title={
                        header.column.getCanSort()
                          ? header.column.getNextSortingOrder() === "asc"
                            ? "Sort ascending"
                            : header.column.getNextSortingOrder() === "desc"
                            ? "Sort descending"
                            : "Clear sort"
                          : undefined
                      }
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: <Icon name="caret up" color="yellow" />,
                        desc: <Icon name="caret down" color="yellow" />,
                      }[header.column.getIsSorted()] ?? null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
}
