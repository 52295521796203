import React from "react";

export default function Legend({ polygons, removePolygon }) {
  const renderLegend = () => {
    return (
      <ul className="legend-list">
        {polygons.map((polygon) => {
          return (
            <li
              key={polygon.ons}
              style={{ color: polygon.colour, cursor: "pointer" }}
              onClick={() => removePolygon(polygon)}
            >
              {polygon.ons}
            </li>
          );
        })}
      </ul>
    );
  };
  return <div>{renderLegend()}</div>;
}
