import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { seatData } from "../logic/apiHandler";
import { Helmet } from "react-helmet-async";
import { Icon, Popup, Button, Container } from "semantic-ui-react";
import Dimmer from "./Dimmer";

export default function PollingDistrictCodesTable({ areaName }) {
  let { areaID } = useParams();

  const [dataLoaded, setDataLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [isCopyPopupOpen, setCopyPopupOpen] = useState(false);
  let timeout;
  const timeoutLength = 1500;

  const handleOpen = () => {
    setCopyPopupOpen(true);
    timeout = setTimeout(() => {
      setCopyPopupOpen(false);
    }, timeoutLength);
  };

  const handleClose = () => {
    setCopyPopupOpen(false);
    clearTimeout(timeout);
  };

  useEffect(() => {
    async function getPDData() {
      setDataLoaded(false);
      setError(null);

      try {
        setData(await seatData.pdCodes(areaID));
        setDataLoaded(true);
      } catch (e) {
        setError(e);
        setDataLoaded(true);
      }
    }

    getPDData();
  }, [areaID]);

  let allRows = prepareRowsToCopy(data);

  function prepareRowsToCopy(data) {
    let res = [];
    for (let row of data) {
      res.push(Object.values(row).join("\t"));
    }
    return res.join("\n");
  }

  const columns = [
    {
      header: "LA",
      accessorKey: "LA",
    },
    {
      header: "PD",
      accessorKey: "PD",
    },
    {
      header: "PDName",
      accessorKey: "PDName",
    },
    {
      header: "code",
      accessorKey: "code",
    },
  ];

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div>
      <Helmet>
        <title>{areaName ?? ""} - Polling Day</title>
      </Helmet>
      <h3>
        Polling District Codes &nbsp;
        <Popup
          trigger={
            <Button basic icon>
              <CopyToClipboard text={allRows}>
                <Icon name="copy outline" size="large" />
              </CopyToClipboard>
            </Button>
          }
          content={`PD codes copied to clipboard`}
          on="click"
          open={isCopyPopupOpen}
          onClose={handleClose}
          onOpen={handleOpen}
          position="top right"
        />
      </h3>
      <Container fluid style={{ position: "relative" }}>
        <div className="sticky-table-container">
          {error || !dataLoaded ? (
            <Dimmer dataLoaded={dataLoaded} error={error} />
          ) : (
            <table>
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th key={header.id} colSpan={header.colSpan}>
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => {
                  return (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => {
                        return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </Container>
    </div>
  );
}
