import axios from "axios";

let endpoint = "/api";

async function getData(path) {
  try {
    return (await axios.get(`${endpoint}/${path}`)).data;
  } catch (err) {
    const httpErrorCode = err?.response?.status ?? -1;
    const error = {
      httpErrorCode,
      errorType: "",
      errorMessage: err.message,
      errors: [],
      method: `${endpoint}/${path}`,
    };
    if (httpErrorCode === 401 || httpErrorCode === 403) {
      error.errorType = "auth";
    } else if (httpErrorCode.toString().startsWith("4")) {
      error.errorType = "client";
    } else if (httpErrorCode.toString().startsWith("5")) {
      error.errorType = "server";
    } else {
      error.errorType = "other";
    }

    let resErrors = err?.response?.data?.errors;
    if (Array.isArray(resErrors)) {
      for (let e of resErrors) {
        if (e?.text) error.errors.push(e.text);
      }
    } else if (typeof err !== "string") {
      error.errors.push(err.toJSON().message);
    }

    throw error;
  }
}

export const seatData = {
  getMPIDCanvassData: async (areaID) => {
    return await getData(`seatData/mpidCanvassData/${areaID}`);
  },
  scoreProfile: async (areaID, score) => {
    return await getData(`seatData/scoreProfile/${areaID}/${score}`);
  },
  scorePerformance: async (areaID, score) => {
    return await getData(`seatData/scorePerformance/${areaID}/${score}`);
  },
  pollingDay: async (areaID, date) => {
    return await getData(`seatData/pollingDay/${areaID}/${date}`);
  },
  shuttleworthResponse: async (areaID) => {
    return await getData(`seatData/shutResponse/${areaID}`);
  },
  switchAnalysis: async (areaID, oldPeriod, newPeriod) => {
    return await getData(`seatData/switchAnalysis/${areaID}/${oldPeriod}/${newPeriod}`);
  },
  info: async (areaID) => {
    return await getData(`seatData/info/${areaID}`);
  },
  geTomorrow: async (areaID) => {
    return await getData(`seatData/geTomorrow/${areaID}`);
  },
  pdCodes: async (areaID) => {
    return await getData(`seatData/tellingCodes/${areaID}`);
  },
  childAreas: async (areaID) => {
    return await getData(`seatData/childAreas/${areaID}`);
  },
  surveyQuestions: async (areaID) => {
    return await getData(`seatData/surveyQuestions/${areaID}`);
  },
  activistCodes: async (areaID) => {
    return await getData(`seatData/activistCodes/${areaID}`);
  },
  walkSummary: async (areaID) => {
    return await getData(`seatData/walkPDSummary/${areaID}`);
  },
  walkData: async (regArea, pd) => {
    return await getData(`seatData/walkPD/${regArea}/${pd}`);
  },
};

export const metaData = {
  registerStatus: async () => {
    return await getData(`metaData/registerStatus`);
  },
  myCampaignStats: async () => {
    return await getData(`metaData/myCampaignStats`);
  },
  topCanvassers: async () => {
    return await getData(`metaData/topCanvassers`);
  },
  getUserList: async () => {
    return await getData(`metaData/dashboardUsers`);
  },
  topForms: async () => {
    return await getData(`metaData/topForms`);
  },
  formStats: async (formID) => {
    return await getData(`metaData/forms/${formID}`);
  },
  allForms: async () => {
    return await getData(`metaData/formsOverallProgress`);
  },
  minivanSyncs: async () => {
    return await getData(`metaData/minivanSyncs`);
  },
};

export const committeeData = {
  list: async () => {
    return await getData(`metaData/committees`);
  },
  users: async (committeeID) => {
    return await getData(`metaData/committees/${committeeID}/users`);
  },
  emailUse: async (committeeID) => {
    return await getData(`metaData/committees/${committeeID}/emailUse`);
  },
  info: async (committeeID) => {
    return await getData(`metaData/committees/${committeeID}`);
  },
};

export const nationalData = {
  geTomorrow: async () => {
    return await getData(`nationalData/geTomorrow`);
  },
  dailyContact: async () => {
    return await getData(`nationalData/dailyContact`);
  },
};

export const userData = {
  allowedConstituencies: async () => {
    return await getData(`allowed`);
  },
  roles: async () => {
    return await getData(`roles`);
  },
  functions: async () => {
    return await getData(`functions`);
  },
  whoami: async () => {
    return await getData(`whoami`);
  },
};

export default {
  seatData,
  metaData,
  committeeData,
  userData,
  nationalData,
};
