import React, { useContext } from "react";
import { Container, Header, CardMeta, CardHeader, CardDescription, CardContent, Card, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { UserContext } from "./UserContext";
import { canAccess, hasConstituencies } from "../logic/userPermissionsHandler";
import { Helmet } from "react-helmet-async";

const MenuCard = (props) => {
  const { link, title, description } = props;

  return (
    <Card>
      <CardContent>
        <CardHeader>
          <Link to={link}>{title}</Link>
        </CardHeader>
        <CardDescription>{description}</CardDescription>
      </CardContent>
    </Card>
  );
};

export default function MetaData() {
  const user = useContext(UserContext);
  return (
    <Container>
      <Helmet>
        <title>MetaData</title>
      </Helmet>
      <Header as="h2">Connect metadata</Header>
      {canAccess(user, "connectCanvassers") ? (
        <MenuCard
          link="/top-canvassers"
          title="Top canvassers"
          description="Top canvassers nationwide from the last 7 days"
        />
      ) : null}

      {canAccess(user, "registerStatus") ? (
        <MenuCard
          link="/register-status-table"
          title="Register status"
          description="Current status in processing electoral registers"
        />
      ) : null}

      {canAccess(user, "connectMetadata") ? (
        <MenuCard link="/my-campaign-stats" title="MyCampaign stats" description="" />
      ) : null}

      {canAccess(user, "connectMetadata") ? (
        <MenuCard link="/top-forms-table" title="Top Forms" description="Highest performing fleet forms" />
      ) : null}

      {canAccess(user, "connectMetadata") ? (
        <MenuCard link="/minivan-syncs" title="MiniVAN Syncs" description="" />
      ) : null}
      {canAccess(user, "connectMetadata") ? (
        <MenuCard link="/forms-processing" title="Forms processing stats" description="Recent forms status" />
      ) : null}
    </Container>
  );
}
