import React from "react";
import { useParams } from "react-router-dom";
import ScoreProfileCharts from "./ScoreProfileCharts";
import { Grid, GridColumn, GridRow } from "semantic-ui-react";
import { Helmet } from "react-helmet-async";

export default function ScoreProfileScreen({ areaName }) {
  let { areaID } = useParams();

  return (
    <div>
      <Helmet>
        <title>{areaName ?? ""} - Score Profiles</title>
      </Helmet>

      <Grid stackable columns={16}>
        <GridRow>
          <GridColumn mobile={16} tablet={8} computer={8} largeScreen={6} widescreen={4}>
            <ScoreProfileCharts scoreName="Con2019" colour="blue" title="Conservative 2019" />
          </GridColumn>
          <GridColumn mobile={16} tablet={8} computer={8} largeScreen={6} widescreen={4}>
            <ScoreProfileCharts scoreName="Lab2019" colour="red" title="Labour 2019" />
          </GridColumn>
          <GridColumn mobile={16} tablet={8} computer={8} largeScreen={6} widescreen={4}>
            <ScoreProfileCharts scoreName="LD2019" colour="#FAA61A" title="Lib Dem 2019" />
          </GridColumn>
          <GridColumn mobile={16} tablet={8} computer={8} largeScreen={6} widescreen={4}>
            <ScoreProfileCharts scoreName="Rem2019" colour="purple" title="Remain 2019" />
          </GridColumn>
        </GridRow>
        <GridRow>
          <GridColumn mobile={16} tablet={8} computer={8} largeScreen={6} widescreen={4}>
            <ScoreProfileCharts scoreName="Bre2019" colour="#18B9D1" title="Brexit party 2019" />
          </GridColumn>
          {areaID.startsWith("S") ? (
            <GridColumn mobile={16} tablet={8} computer={8} largeScreen={6} widescreen={4}>
              <ScoreProfileCharts scoreName="SNP2019" colour="yellow" title="SNP 2019" />
            </GridColumn>
          ) : null}
          <GridColumn mobile={16} tablet={8} computer={8} largeScreen={6} widescreen={4}>
            <ScoreProfileCharts scoreName="Age2024" colour="purple" title="Age 2024" />
          </GridColumn>
        </GridRow>
      </Grid>
    </div>
  );
}
