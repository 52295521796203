import axios from "axios";
import sampleGeocodes from "../sample-data/geocodes.json";

export async function getGeocodes(LA, PD, table) {
  try {
    // let res = await axios.get('a url')
    // if (res.data.length === 0) {
    //   return "No markers for this Polling District";
    // }
    return sampleGeocodes;
  } catch (err) {
    console.log(err);
    if (res.status.startsWith(4)) {
      return "Polling District does not exist";
    }
  }
}

export async function getPolygonCoordinates(layer, ons) {
  try {
    let res = await axios.get(`https://dashboard.libdems.org.uk/drupal/getKMLfile2/${layer}/${ons}`);
    let data = res.data.split("<coordinates>")[1].split("</coordinates>")[0];
    data = data.split(" ");
    let polygonCoordinates = [];
    for (let coordinates of data) {
      coordinates = coordinates.split(",");
      polygonCoordinates.push({ lat: parseFloat(coordinates[1]), lng: parseFloat(coordinates[0]) });
    }
    return polygonCoordinates;
  } catch (err) {
    console.log(err);
  }
}
