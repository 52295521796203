import React from "react";

export const Marker = ({ inhabs }) => (
  <div className="pin">
    {inhabs > 0 ? (
      <img src={"https://maps.gstatic.com/intl/en_us/mapfiles/markers2/measle.png"} className="pin-icon" />
    ) : (
      <img src={"https://maps.gstatic.com/intl/en_us/mapfiles/markers2/measle_blue.png"} className="pin-icon" />
    )}
  </div>
);
