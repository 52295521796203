import React, { useEffect, useState, useMemo } from "react";
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { Helmet } from "react-helmet-async";
import { Icon, Header, Button } from "semantic-ui-react";
import api from "../logic/apiHandler";
import Dimmer from "./Dimmer";
import { useCSVDownloader } from "react-papaparse";
import { generateRegisterStatusPDF } from "../logic/registerStatusPDFGenerator";

export default function RegisterStatusTable() {
  const [registerStatusData, setRegisterStatusData] = useState([]);
  const [error, setError] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const { CSVDownloader, Type } = useCSVDownloader();

  useEffect(() => {
    (async () => {
      setDataLoaded(false);
      setError(null);

      try {
        setRegisterStatusData(await api.metaData.registerStatus());
        setDataLoaded(true);
      } catch (e) {
        console.error(e);
        setDataLoaded(true);
        setError(e);
      }
    })();
  }, []);

  const columns = useMemo(
    () => [
      {
        header: "laNum",
        accessorKey: "laNum",
        sortDescFirst: false,
      },
      {
        header: "name",
        accessorKey: "name",
      },
      {
        header: "euroregion",
        accessorKey: "euroregion",
      },
      {
        header: "important",
        accessorKey: "important",
      },
      {
        header: "nextUpdate",
        accessorKey: "nextUpdate",
        cell: (props) => props.getValue().split("T")[0],
      },
      {
        header: "lastAVUpdate",
        accessorKey: "lastAVUpdate",
        cell: (props) => props.getValue().split("T")[0],
      },
      {
        header: "Nov",
        accessorKey: "November",
        cell: (props) => statusDetails[props.getValue()].abbreviation,
      },
      {
        header: "Dec",
        accessorKey: "December",
        cell: (props) => statusDetails[props.getValue()].abbreviation,
      },
      {
        header: "Jan",
        accessorKey: "January",
        cell: (props) => statusDetails[props.getValue()].abbreviation,
      },
      {
        header: "Feb",
        accessorKey: "February",
        cell: (props) => statusDetails[props.getValue()].abbreviation,
      },
      {
        header: "Mar",
        accessorKey: "March",
        cell: (props) => statusDetails[props.getValue()].abbreviation,
      },
      {
        header: "Apr",
        accessorKey: "April",
        cell: (props) => statusDetails[props.getValue()].abbreviation,
      },
      {
        header: "May",
        accessorKey: "May",
        cell: (props) => statusDetails[props.getValue()].abbreviation,
      },
      {
        header: "June",
        accessorKey: "June",
        cell: (props) => statusDetails[props.getValue()].abbreviation,
      },
      {
        header: "July",
        accessorKey: "July",
        cell: (props) => statusDetails[props.getValue()].abbreviation,
      },
      {
        header: "Aug",
        accessorKey: "August",
        cell: (props) => statusDetails[props.getValue()].abbreviation,
      },
      {
        header: "Sept",
        accessorKey: "September",
        cell: (props) => statusDetails[props.getValue()].abbreviation,
      },
      {
        header: "Oct",
        accessorKey: "October",
        cell: (props) => statusDetails[props.getValue()].abbreviation,
      },
    ],
    []
  );

  const statusDetails = {
    SKIPPED: { abbreviation: "SK", backgroundColour: "green", textColour: "white" },
    PROCESSED: { abbreviation: "PR", backgroundColour: "blue", textColour: "white" },
    RECEIVED: { abbreviation: "R", backgroundColour: "#FDBB30", textColour: "black" },
    "NOT-RECEIVED": { abbreviation: "NR", backgroundColour: "red", textColour: "white" },
    "PART PROCESSED": { abbreviation: "PP", backgroundColour: "grey", textColour: "white" },
    "": { abbreviation: "", colour: "transparent", textColour: "black" },
  };

  const table = useReactTable({
    columns,
    data: registerStatusData,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    intialState: {
      sorting: [{ id: "laNum", desc: false }],
    },
  });

  return (
    <div>
      <Helmet>
        <title>Register Status</title>
      </Helmet>
      <Header as="h2">Register Areas</Header>

      <div>
        <h3>Register Areas</h3>
        <Button color="yellow">
          <CSVDownloader
            style={{ color: "white" }}
            type={Type.Link}
            filename={"registerStatusTable"}
            bom={true}
            data={registerStatusData}
          >
            Download CSV
          </CSVDownloader>
        </Button>
        <Button onClick={() => generateRegisterStatusPDF(registerStatusData)} color="yellow">
          Download PDF
        </Button>
      </div>
      <Dimmer error={error} dataLoaded={dataLoaded} />
      <div className="sticky-table-container">
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div
                        className={header.column.getCanSort() ? "cursor-pointer select-none" : ""}
                        onClick={header.column.getToggleSortingHandler()}
                        title={
                          header.column.getCanSort()
                            ? header.column.getNextSortingOrder() === "asc"
                              ? "Sort ascending"
                              : header.column.getNextSortingOrder() === "desc"
                              ? "Sort descending"
                              : "Clear sort"
                            : undefined
                        }
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: <Icon name="caret up" color="yellow" />,
                          desc: <Icon name="caret down" color="yellow" />,
                        }[header.column.getIsSorted()] ?? null}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    let cellContents = cell.getValue();
                    if (statusDetails[cellContents]) {
                      return (
                        <td
                          style={{
                            background: statusDetails[cellContents].backgroundColour,
                            color: statusDetails[cellContents].textColour,
                          }}
                          key={cell.id}
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      );
                    } else {
                      return <td key={cell.id}> {flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
