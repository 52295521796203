import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { Container, Header } from "semantic-ui-react";
import { UserContext } from "./UserContext";
import { metaData } from "../logic/apiHandler";
import { Icon } from "semantic-ui-react";

export default function UserData() {
  const [userList, setUserList] = useState([]);
  const user = useContext(UserContext);

  useEffect(() => {
    setUserList([]);
    (async () => {
      try {
        let data = await metaData.getUserList();
        data.map((u) => {
          u.areas = u?.hasNationalPermissions
            ? "National access"
            : u?.districts.length === 0
            ? "No seat access"
            : u.districts;
          return u;
        });
        setUserList(data);
      } catch (e) {
        console.log("e", e);
      }
    })();
  }, []);

  const columns = [
    {
      header: "ID",
      accessorKey: "userID",
    },
    {
      header: "Forename",
      accessorKey: "forename",
    },
    {
      header: "Surname",
      accessorKey: "surname",
    },
    {
      header: "Email",
      accessorKey: "email",
    },
    {
      header: "Roles",
      accessorKey: "roles",
    },
    {
      header: "Areas",
      accessorKey: "areas",
    },
    {
      header: "Last Access",
      accessorKey: "lastAccess",
      cell: ({ row }) => lastSeen(row.original.lastAccess),
      sortingFn: (a, b) => {
        return new Date(a.original.lastAccess).getTime() > new Date(b.original.lastAccess).getTime() ? -1 : 1;
      },
    },
  ];

  const table = useReactTable({
    columns,
    data: userList,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  function lastSeen(lastSeenDate) {
    if (!lastSeenDate) {
      return "not logged in";
    }
    const now = new Date();
    lastSeenDate = new Date(lastSeenDate);

    const differenceMins = Math.round((now - lastSeenDate) / 60000);
    const differenceHours = Math.round(differenceMins / 60);
    const differenceDays = Math.round(differenceHours / 24);

    if (differenceMins <= 1) {
      return "a minute ago";
    } else if (differenceMins <= 59) {
      return `${differenceMins} minutes ago`;
    } else if (differenceHours === 1) {
      return "an hour ago";
    } else if (differenceHours < 24) {
      return `${differenceHours} hours ago`;
    } else if (differenceDays === 1) {
      return `a day ago`;
    } else if (differenceDays < 30) {
      return `${differenceDays} days ago`;
    } else if (differenceDays <= 31) {
      return "last month";
    } else {
      return lastSeenDate.toLocaleDateString("en-GB");
    }
  }

  return (
    <Container fluid>
      <Header as="h2">Dashboard user data</Header>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : (
                    <div
                      className={header.column.getCanSort() ? "cursor-pointer select-none" : ""}
                      onClick={header.column.getToggleSortingHandler()}
                      title={
                        header.column.getCanSort()
                          ? header.column.getNextSortingOrder() === "asc"
                            ? "Sort ascending"
                            : header.column.getNextSortingOrder() === "desc"
                            ? "Sort descending"
                            : "Clear sort"
                          : undefined
                      }
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: <Icon name="caret up" color="yellow" />,
                        desc: <Icon name="caret down" color="yellow" />,
                      }[header.column.getIsSorted()] ?? null}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Container>
  );
}
