import React, { useMemo, useState, useEffect } from "react";
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { metaData } from "../logic/apiHandler";
import { Icon, Popup, Button } from "semantic-ui-react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Helmet } from "react-helmet-async";
import Dimmer from "./Dimmer";

export default function TopCanvassersTable() {
  const [topCanvassers, setTopCanvassers] = useState([]);
  const [isCopyPopupOpen, setCopyPopupOpen] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [error, setError] = useState(null);

  let timeout;
  const timeoutLength = 1500;

  const handleOpen = () => {
    setCopyPopupOpen(true);
    timeout = setTimeout(() => {
      setCopyPopupOpen(false);
    }, timeoutLength);
  };

  const handleClose = () => {
    setCopyPopupOpen(false);
    clearTimeout(timeout);
  };

  useEffect(() => {
    async function getCanvasserData() {
      setDataLoaded(false);
      setError(null);

      try {
        setTopCanvassers(await metaData.topCanvassers());
        setDataLoaded(true);
      } catch (e) {
        setError(e);
        setDataLoaded(true);
      }
    }

    getCanvasserData();
  }, []);

  let allRows = prepareRowsToCopy(topCanvassers);

  function prepareRowsToCopy(data) {
    let res = [];
    if (data.length > 0) {
      res.push(Object.keys(data[0]).join("\t"));
    }
    for (let row of data) {
      res.push(Object.values(row).join("\t"));
    }
    return res.join("\n");
  }

  const columns = useMemo(
    () => [
      {
        header: "VAN ID",
        accessorKey: "VANID",
      },
      {
        header: "Attempts last 7 days",
        accessorKey: "doorPhoneContactsLast7Days",
      },
      {
        header: "Attempts last 3 months",
        accessorKey: "doorPhoneContactsLast3Mths",
      },
      {
        header: "Attempts last 18 months",
        accessorKey: "doorPhoneContactsLast18Mths",
      },
      {
        header: "First Name",
        accessorKey: "FirstName",
      },
      {
        header: "Last Name",
        accessorKey: "LastName",
      },
      {
        header: "Postal Code",
        accessorKey: "PostalCode",
      },
      {
        header: "SF ID",
        accessorKey: "SFID",
      },
      {
        header: "Mobile",
        accessorKey: "mobile",
      },
      {
        header: "Email",
        accessorKey: "email",
      },
      {
        header: "Member status",
        accessorKey: "memberStatus",
      },
    ],
    []
  );

  const table = useReactTable({
    columns,
    data: topCanvassers,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div>
      <Helmet>
        <title>Top Canvassers</title>
      </Helmet>
      <h3>
        Top Canvassers &nbsp;
        <Popup
          trigger={
            <Button basic icon>
              <CopyToClipboard text={allRows}>
                <Icon name="copy outline" size="large" />
              </CopyToClipboard>
            </Button>
          }
          content={`Top canvassers copied to clipboard`}
          on="click"
          open={isCopyPopupOpen}
          onClose={handleClose}
          onOpen={handleOpen}
          position="top right"
        />
      </h3>
      <p>Canvassers with the most canvass attempts in the last 7 days</p>
      {!dataLoaded || error ? (
        <Dimmer error={error} dataLoaded={dataLoaded} />
      ) : (
        <div className="sticky-table-container">
          <table>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className={header.column.getCanSort() ? "cursor-pointer select-none" : ""}
                      onClick={header.column.getToggleSortingHandler()}
                      title={
                        header.column.getCanSort()
                          ? header.column.getNextSortingOrder() === "asc"
                            ? "Sort ascending"
                            : header.column.getNextSortingOrder() === "desc"
                            ? "Sort descending"
                            : "Clear sort"
                          : undefined
                      }
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: <Icon name="caret up" color="yellow" />,
                        desc: <Icon name="caret down" color="yellow" />,
                      }[header.column.getIsSorted()] ?? null}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
