import React, { useEffect, useState } from "react";
import api from "../logic/apiHandler";
import SwitchAnalysisForm from "./SwitchAnalysisForm";
import SwitchAnalysisTable from "./SwitchAnalysisTable";
import { Container } from "semantic-ui-react";
import { useParams } from "react-router-dom";
import Dimmer from "./Dimmer";
import { Helmet } from "react-helmet-async";

export default function SwitchAnalysisScreen({ areaName }) {
  const { areaID } = useParams();
  const [switchAnalysisData, setSwitchAnalysisData] = useState([]);
  const [displayedOptions, setDisplayedOptions] = useState("");
  const [dataLoaded, setDataLoaded] = useState(true);
  const [error, setError] = useState(null);

  async function updateSwitchAnalysisData(oldPeriod, newPeriod) {
    setDataLoaded(false);
    setError(null);

    try {
      let data = await api.seatData.switchAnalysis(areaID, oldPeriod, newPeriod);
      setSwitchAnalysisData(data);
      setDataLoaded(true);
    } catch (e) {
      setError(e);
      setDataLoaded(true);
    }
  }

  return (
    <>
      <Helmet>
        <title>{areaName ?? ""} - Switch Analysis</title>
      </Helmet>
      <Container fluid>
        <SwitchAnalysisForm
          updateSwitchAnalysisData={updateSwitchAnalysisData}
          setDisplayedOptions={setDisplayedOptions}
        />
        <h4>Currently displaying: {displayedOptions}</h4>
        <Container fluid style={{ position: "relative" }}>
          <Dimmer dataLoaded={dataLoaded} error={error} />
          <SwitchAnalysisTable data={switchAnalysisData} />
        </Container>
      </Container>
    </>
  );
}
