import React, { useState } from "react";
import Map from "./MapComponent";
import { getGeocodes, getPolygonCoordinates } from "../logic/pdhaFetcher";
import BoundaryForm from "./MapBoundaryForm";
import LAForm from "./MapLAForm";
import Legend from "./MapLegend";
import { Button, ButtonGroup, ButtonOr } from "semantic-ui-react";
import { Helmet } from "react-helmet-async";

export default function PDHACheckup({ setErrorMessage }) {
  const [mapsAPI, setMapsAPI] = useState(null); // TODO this is an awful name
  const [geocodes, setGeocodes] = useState(null);
  const [centre, setCentre] = useState({ lat: 53.9449775, lng: -2.520879 });
  const [zoomLevel, setZoomLevel] = useState(6);
  const [polygons, setPolygons] = useState([]);
  const [colours, setColours] = useState(["#0303FC", "#FAA61A", "#E60B0B", "#30f205"]);
  const [mapType, setMapType] = useState("satellite");

  const drawPolygon = async (boundary, layer) => {
    const coordinates = await getPolygonCoordinates(layer, boundary);
    if (!coordinates) {
      setErrorMessage("Invalid ONS");
    } else {
      if (polygons.map((polygon) => polygon.ons).includes(boundary)) {
        setErrorMessage("Boundary already added");
      } else {
        setErrorMessage(null);
        const colour = colours[0];
        colours.shift();
        setColours(colours);
        const polygon = new mapsAPI.maps.Polygon({
          paths: coordinates,
          strokeColor: colour,
          strokeOpacity: 0.6,
          strokeWeight: 2,
          fillColor: colour,
          fillOpacity: 0.25,
        });
        setPolygons([...polygons, { ons: boundary, colour, polygonDetails: polygon }]);
        polygon.setMap(mapsAPI.map);
      }
    }
  };

  const removePolygon = (polygonToRemove) => {
    setPolygons(polygons.filter((polygon) => polygon.ons !== polygonToRemove.ons));
    setColours([...colours, polygonToRemove.colour]);
    polygonToRemove.polygonDetails.setMap(null);
  };

  const getMapCentre = (geocodes) => {
    const lats = geocodes.map((codesArr) => codesArr[0]);
    const lngs = geocodes.map((codesArr) => codesArr[1]);
    const midLat = (Math.min(...lats) + Math.max(...lats)) / 2;
    const midLng = (Math.min(...lngs) + Math.max(...lngs)) / 2;
    setCentre({ lat: midLat, lng: midLng });
    setZoomLevel(11);
  };

  const addMarkers = async (LA, PD, table) => {
    const geocodes = await getGeocodes(LA, PD, table);
    if (typeof geocodes === "string") {
      setErrorMessage(geocodes);
    } else {
      setGeocodes(geocodes);
      getMapCentre(geocodes);
    }
  };

  return (
    <div>
      <Helmet>PDHA Checkup</Helmet>
      <LAForm addMarkers={addMarkers} clearMarkers={setGeocodes} setErrorMessage={setErrorMessage} />
      <BoundaryForm drawPolygon={drawPolygon} boundariesQty={polygons.length} setErrorMessage={setErrorMessage} />
      <Legend colourMap={colours} polygons={polygons} removePolygon={removePolygon} />
      <ButtonGroup>
        <Button value="Satellite" onClick={(e) => setMapType(e.target.value)}>
          Satellite
        </Button>
        <ButtonOr />
        <Button value="Road Map" onClick={(e) => setMapType(e.target.value)}>
          Road Map
        </Button>
      </ButtonGroup>
      <Map locations={geocodes} zoomLevel={zoomLevel} centre={centre} setMapsAPI={setMapsAPI} mapType={mapType} />
    </div>
  );
}
