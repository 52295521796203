import React, { useContext } from "react";
import { Menu, Container, MenuMenu } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import { UserContext } from "./UserContext";
import { canAccess, hasConstituencies } from "../logic/userPermissionsHandler";

export default function NavBar() {
  const user = useContext(UserContext);
  let liveVersion = window.location.origin === "https://dash.libde.ms";

  let color = liveVersion ? "black" : "violet";

  return (
    <Container>
      <Menu className="ui top inverted fixed menu" color={color} style={{ overflow: "auto" }}>
        <Menu.Item as={NavLink} to="/" name="home" />
        {canAccess(user, "basicCanvassing") && hasConstituencies(user) ? (
          <Menu.Item as={NavLink} to="/seatData" name="Seat data">
            Seat data
          </Menu.Item>
        ) : (
          <></>
        )}

        {canAccess(user, "connectMetadata") ? (
          <Menu.Item as={NavLink} to="/metaData" name="meta data">
            Metadata
          </Menu.Item>
        ) : (
          <></>
        )}

        {canAccess(user, "nationalStats") ? (
          <Menu.Item as={NavLink} to="/nationalData" name="national data">
            National data
          </Menu.Item>
        ) : (
          <></>
        )}

        {canAccess(user, "privateCharlie") ? (
          <Menu.Item as={NavLink} to="/committeeData" name="committees data">
            Committee Data
          </Menu.Item>
        ) : (
          <></>
        )}

        <MenuMenu position="right">
          <Menu.Item>{user.isValid ? user.email : ""}</Menu.Item>
        </MenuMenu>
      </Menu>
    </Container>
  );
}
