import React, { useEffect, useState } from "react";
import { List, ListItem } from "semantic-ui-react";
import SeatLevelLabel from "./SeatLevelLabel";
import api from "../logic/apiHandler";
import { useParams, Link } from "react-router-dom";
import Dimmer from "./Dimmer";

export default function ChildrenConstituencies() {
  const [constituencies, setConstituencies] = useState({});
  const [dataLoaded, setDataLoaded] = useState(false);
  const [error, setError] = useState(null);
  const { areaID } = useParams();

  useEffect(() => {
    async function getConstituencies() {
      setDataLoaded(false);
      setError(null);

      try {
        setConstituencies(await api.seatData.childAreas(areaID));
        setDataLoaded(true);
      } catch (e) {
        setDataLoaded(true);
        setError(true);
      }
    }

    getConstituencies();
  }, []);

  function renderConstituencies() {
    return constituencies.children ? (
      constituencies?.children.map((constituency) => {
        const tag = constituency.label ? <SeatLevelLabel label={constituency.label.toLowerCase()} /> : null;
        return (
          <ListItem key={constituency.code}>
            <Link to={`/seatData/${constituency.code}`}>{constituency.name}</Link> {tag}
          </ListItem>
        );
      })
    ) : (
      <></>
    );
  }

  return (
    <div>
      <Dimmer error={error} dataLoaded={dataLoaded} />
      <List bulleted>{renderConstituencies()}</List>
    </div>
  );
}
