import React from "react";
import GoogleMap from "google-map-react";
import { Marker } from "./MapMarker";

export default function Map({ setMapsAPI, locations, zoomLevel, centre, mapType }) {
  const mapHeight = window.innerHeight * 0.7;

  const handleApiLoaded = (map, maps) => {
    setMapsAPI({ map, maps });
  };

  return (
    <div className="map">
      <div className="google-map" style={{ height: mapHeight }}>
        <GoogleMap
          bootstrapURLKeys={{ key: "" }}
          center={centre}
          zoom={zoomLevel}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
          options={{ mapTypeId: mapType }}
        >
          {locations
            ? locations.map((location) => {
                return <Marker key={location[0]} lat={location[0]} lng={location[1]} inhabs={location[2]} />;
              })
            : null}
        </GoogleMap>
      </div>
    </div>
  );
}
