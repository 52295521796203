import React, { useState } from "react";
import { Button, Dropdown, Form, Input } from "semantic-ui-react";

export default function BoundaryForm({ drawPolygon, boundariesQty, setErrorMessage }) {
  const [boundary, setBoundary] = useState(null);
  const [layer, setLayer] = useState("live");

  const handleSubmit = () => {
    if (!boundary || !layer) {
      setErrorMessage("Missing required field(s)");
    } else if (boundariesQty === 4) {
      setErrorMessage("Please clear a boundary before adding a new one");
    } else {
      setErrorMessage(null);
      drawPolygon(boundary, layer);
    }
  };

  return (
    <Form>
      <form className="ui form">
        <div className="field">
          <label>Boundary</label>
          <Input
            focus
            type="text"
            name="boundary"
            placeholder="Boundary"
            onChange={(e) => setBoundary(e.target.value)}
          />
        </div>
        <div className="field">
          <label>Layer</label>
          <Dropdown
            onChange={(e, d) => setLayer(d.value)}
            placeholder="Layer"
            fluid
            selection
            options={[
              {
                text: "Live",
                value: "Live",
                key: "live",
              },
              {
                text: "Previous",
                value: "Previous",
                key: "previous",
              },
            ]}
          />
        </div>
      </form>

      <Button onClick={handleSubmit}>Add Boundary</Button>
    </Form>
  );
}
