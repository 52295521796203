import React, { useState } from "react";
import { Button, Dropdown, DropdownItem, Form, Input } from "semantic-ui-react";

export default function LAForm({ addMarkers, clearMarkers, setErrorMessage }) {
  const [LA, setLA] = useState(null);
  const [PD, setPD] = useState(null);
  const [table, setTable] = useState("changes");
  const [hasMarkers, setHasMarkers] = useState(false);

  const handleSubmit = () => {
    if (!LA || !PD || !table) {
      setErrorMessage("Missing required field(s)");
    } else {
      addMarkers(LA, PD, table);
      setHasMarkers(true);
    }
  };

  const clearForm = () => {
    setHasMarkers(false);
    setLA(null);
    setPD(null);
    setTable(null);
    clearMarkers(null);
  };

  return (
    <Form className="ui form">
      <div className="field">
        <label>LA</label>
        <Input focus type="text" name="LA" placeholder="LA" onChange={(e) => setLA(e.target.value)} />
      </div>
      <div className="field">
        <Input focus type="text" name="PD" placeholder="PD" onChange={(e) => setPD(e.target.value)} />
      </div>
      <Dropdown
        onChange={(e, d) => setTable(d.value)}
        placeholder="Changes"
        fluid
        selection
        options={[
          { text: "Changes", value: "Changes", key: "changes" },
          { text: "Live", value: "Live", key: "live" },
        ]}
      />
      <Button variant="primary" onClick={handleSubmit}>
        Update
      </Button>
      {hasMarkers ? <Button onClick={clearForm}>Clear</Button> : null}
    </Form>
  );
}
