import React, { useState } from "react";
import { Dropdown, DropdownHeader } from "semantic-ui-react";
import { generatePath, Link, useNavigate, useParams } from "react-router-dom";
import SeatLevelLabel from "./SeatLevelLabel";

export default function DropdownSearchMenu({ menuType, options }) {
  const [highlightedRow, setHighlightedRow] = useState(null);
  const params = useParams();
  const navigate = useNavigate();

  function getMenuOptions() {
    if (menuType === "constituency") {
      return getConstituencyOptions();
    } else if (menuType === "committee") {
      return getCommitteeOptions();
    }
  }

  function getConstituencyOptions() {
    let menuOptions = [];
    if (options) {
      Object.keys(options).map((areaType) => {
        let areaName = areaType.charAt(0).toUpperCase() + areaType.slice(1);
        areaName = areaName.match(/[A-Z][a-z]+|[0-9]+/g).join(" ");

        menuOptions.push({
          text: areaName,
          value: areaName,
          content: <DropdownHeader content={areaName} />,
          disabled: true,
        });

        const areaTypeAbbreviation = getAreaTypeAbbreviation(areaType);
        for (let constituency of options[areaType]) {
          const tag = constituency.label ? (
            <SeatLevelLabel label={constituency.label.toLowerCase()} floatright />
          ) : null;
          menuOptions.push({
            as: Link,
            key: `${areaType}:${constituency.code}`,
            text: `${constituency.name} - ${areaTypeAbbreviation}`,
            value: constituency.code,
            to: generatePath("/seatData/:areaID/*", { ...params, areaID: constituency.code }),
            label: tag,
          });
        }
      });
    }
    return menuOptions;
  }

  function getAreaTypeAbbreviation(areaType) {
    switch (areaType) {
      case "westminster":
        return "W";
      case "westminster2024":
        return "W24";
      case "localAuthority":
        return "LA";
      case "european":
        return "Eu";
      default:
        return;
    }
  }

  function getCommitteeOptions() {
    return options.map((committee) => {
      return {
        as: Link,
        key: committee.committeeID,
        text: committee.committeeName,
        value: committee.committeeID,
        to: generatePath("/committeeData/:committeeID/*", { ...params, committeeID: committee.committeeID }),
      };
    });
  }

  function handleKeyDown(e) {
    if (e.keyCode === 13) {
      if (menuType === "constituency") {
        navigate(generatePath("/seatData/:areaID/*", { ...params, areaID: highlightedRow }));
      } else if (menuType === "committee") {
        navigate(generatePath("/committeeData/:committeeID/*", { ...params, committeeID: highlightedRow }));
      }
    }
  }

  return (
    <Dropdown
      placeholder="Switch to:"
      search
      selection
      options={getMenuOptions()}
      onChange={(e, d) => {
        setHighlightedRow(d.value);
      }}
      onKeyDown={(e) => handleKeyDown(e)}
      closeOnChange={true}
    />
  );
}
